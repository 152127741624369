<template>
  <div class="d-flex flex-column" style="min-height: 400px">
    <div>
      <div
        style="
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          justify-items: center;
          column-gap: 10px;
          row-gap: 16px;
          justify-content: space-evenly;
          grid-template-areas: 'A A A';
        "
      >
        <tile-navigation-button
          :key="test.test_name"
          :button="{
            text: test.test_verbose_name || test.test_name,
          }"
          v-for="test in tests"
          ><template #image
            ><img
              v-if="test.icon"
              @click.self="() => openTest(test)"
              style="width: 95px; height: 95px"
              :src="test.icon"
            />
            <img
              @click.self="() => openTest(test)"
              style="width: 95px; height: 95px"
              v-else
              src="@main/assets/img/svg/menu/rates.svg"
              :alt="test.test_verbose_name || test.test_name"
            /> </template
        ></tile-navigation-button>
      </div>
    </div>
    <div
      class="bottom-part d-flex justify-content-center mb-1 pt-2 pb-2"
      style="padding: 0px 57px; background-color: #f8f8f8; margin-top: 50px"
    >
      <b-button
        style="
          line-break: anywhere !important;
          line-break: wrap !important;
          white-space: pre-wrap !important;
          max-width: 400px !important;
        "
        class="text-center d-block"
        target="_blank"
        href="https://tracker.easyprbot.com/testbugs-form"
        variant="link"
        >Нашли ошибку? Заполните эту форму</b-button
      >
    </div>
  </div>
</template>

<script>
import education from '@main/api/education';
import { mapActions } from 'vuex';
import SpeedIcon from '@main/assets/img/icons/speedometer.png';
import TileNavigationButton from '../reusable/elements/buttons/TileNavigationButton.vue';

export default {
  components: { TileNavigationButton },
  methods: {
    ...mapActions('notifications', ['setWindow']),
    openTest(test) {
      this.setWindow({
        iconPath: SpeedIcon,
        iconWidth: 210,
        iconHeight: 160,
        html: '<h4 class="fw-500 mt-4" style="font-size: 20px">Погнали?</h4>',
        buttons: [
          {
            text: 'Читать правила',
            replaceClass: 'ph-42 w-100 w-xsm-45 ml-1 mr-1 px-2',
            attrs: {
              href:
                test.test_rules ||
                'https://docs.google.com/document/d/14veZluXyC0DTYtNEkBGstCieG8V9YvjxsLtj1MBcUe0/',
              target: '_blank',
              variant: 'outline-default',
              style: 'padding-left: 5px !important; padding-right: 5px !important',
            },
          },
          {
            text: 'Да',
            attrs: { variant: 'yellow', class: 'w-50' },
            replaceClass: 'ph-42 w-100 w-xsm-45 ml-1 mr-1 px-2',
            handler: () => {
              window.location.href = `${
                window.location.origin + window.location.pathname
              }#/education/tests/${Math.random()}?generate=1&test_name=${test.test_name}`;
              this.setWindow(null);
            },
          },
          {
            text: 'Вернуться назад',
            attrs: {
              variant: 'link',
              style:
                'padding-left: 5px !important; padding-right: 5px !important; margin-top: 20px !important',
            },
            handler: () => {
              this.setWindow(null);
            },
          },
        ],
      });
    },
  },
  data: () => ({
    tests: [],
  }),
  props: {
    course: {
      default: null,
    },
  },
  async mounted() {
    const result = await education.v2.tests.meta.userList({
      course: this.course || this.$route.query.course,
    });
    localStorage.setItem('lastCourse', this.course || this.$route.query.course);
    this.tests = result.data_array;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .open-test__btn {
  width: 50% !important;
}
</style>
